import React from 'react'
import {graphql} from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import {LGHeader, MDHeader} from '../components/typography';
import {Helmet} from "react-helmet";
import {Button, ButtonNewWindow, DonateButton, VariantWhiteOutlineYellowHover} from "../components/buttons";
import Img from "gatsby-image";

const LinkBlock = ({title, blurb, links}) => (
  <div className="flex flex-col justify-around items-center">
    {links.map((link) => {
      switch (link.linkType) {
        case 'Internal Page':
          return <a><Button variant={VariantWhiteOutlineYellowHover} className="px-6 my-4 max-w-link-tree" to={link.linkUrl} noMinWidth>{link.headerNavLinkName}</Button></a>
        case 'External Link':
          return <ButtonNewWindow variant={VariantWhiteOutlineYellowHover} className="px-6 my-4 max-w-link-tree" to={link.linkUrl} noMinWidth>{link.headerNavLinkName}</ButtonNewWindow>
        default:
          return <div><DonateButton variant={VariantWhiteOutlineYellowHover} className="px-6 my-4 max-w-link-tree" to={link.linkUrl} text={link.headerNavLinkName} /></div>
      }
    })}
  </div>
)




/**
 * linktree-esque page template
 * @param data
 * @param location
 * @returns {JSX.Element}
 * @constructor
 */
const LinkPageTemplate = ({data, location}) => {
  const linkPage = get(data, 'contentfulCustomLinkTree')
  const siteTitle = get(data, 'site.siteMetadata.title')
  console.log('columns', linkPage.linkColumns)

  return (
    <Layout location={location}>
      <Helmet title={`${linkPage.title} | ${siteTitle}`} />
      <section className="p-8 md:p-16 text-white">
        <div className="max-w-screen-lg mx-auto px-8 md:px-16 text-white">
          <div className="w-full">
            <LGHeader className="text-center pt-6 pb-12 leading-extra-tight md:leading-extra-tight">{linkPage.title}</LGHeader>
            {/*<MarkdownContainer className="py-8 text-left">*/}
            {/*  {linkPage.blurb.childMarkdownRemark.html}*/}
            {/*</MarkdownContainer>*/}
          </div>
          <LinkBlock {...linkPage.linkColumns[0]} />
        </div>
      </section>
      <section className="pb-16 pr-16 pl-16 md:pb-16 md:pr-16 md:pl-16 text-white max-w-screen-lg mx-auto">
        <MDHeader className="pt-6 pb-12 leading-extra-tight md:leading-extra-tight text-left">{linkPage.imageDownloadHeader}</MDHeader>
        {/*{linkPage.imageDownloadBlurb &&*/}
        {/*  <MarkdownContainer className="py-8 text-left">*/}
        {/*    {linkPage.imageDownloadBlurb.childMarkdownRemark.html}*/}
        {/*  </MarkdownContainer>*/}
        {/*}*/}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {linkPage.pdfDownloadGrid.map((download) => (
            <a target="_blank" rel="noreferrer" href={download.pdf.file.url}>
              <Img
                alt={download.description}
                fluid={download.thumbnailImage.fluid}
              />
            </a>
            )
          )}
        </div>
      </section>
    </Layout>
  )
}

export default LinkPageTemplate

export const pageQuery = graphql`
  query LinkPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulCustomLinkTree(slug: { eq: $slug }) {
      title
      blurb {
        childMarkdownRemark {
          html
        }
      }
      linkColumns {
        title
        blurb {
          childMarkdownRemark {
            html
          }
        }
        links {
          headerNavLinkName
          linkType
          linkUrl
        }
      }
      imageDownloadHeader
      imageDownloadBlurb {
        childMarkdownRemark {
          html
        }
      }
      pdfDownloadGrid {
        thumbnailImage {
          fluid(maxWidth: 800, maxHeight: 600, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid
          }
        }
        pdf {
          file { url }
        }
        description 
      }
    }
  }
`
